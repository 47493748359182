import axios from 'axios';

import {
  MarketingAssistantContentRequestModel,
  MarketingAssistantRequestModel,
  MarketingAssistantUserInfoModel,
  SaveGenerationRequestModel,
} from '@/models/MarketingAssistant';

class MarketingAssistantAPI {
  static getMarketingAssistantUserInfo = async ({ accountId, artistId }: { accountId?: string; artistId?: string }) =>
    await axios.get<MarketingAssistantUserInfoModel>(`/account/${accountId}/artist-info/${artistId}`);

  static getMarketingAssistantContent = async ({
    accountId,
    pageSize,
    continuationToken,
  }: {
    accountId?: string;
    pageSize: number;
    continuationToken?: string;
  }) =>
    await axios.get(
      `/marketing-assistant/account/${accountId}?pageSize=${pageSize}&continuationToken=${continuationToken ?? ''}`
    );

  static generatePressRelease = async ({ data }: { data: MarketingAssistantRequestModel }) =>
    await axios.post(`/marketing-assistant/press-release`, data);

  static generateArtistBio = async ({ data }: { data: MarketingAssistantRequestModel }) =>
    await axios.post(`/marketing-assistant/artist-bio`, data);

  static saveGeneration = async ({ data }: { data: SaveGenerationRequestModel }) =>
    await axios.post(`/marketing-assistant/save`, data);

  static editGeneration = async ({ data }: { data: MarketingAssistantContentRequestModel }) =>
    await axios.post(`/marketing-assistant/edit`, data);

  static updateMarketingAssistantUserInfo = async ({
    accountId,
    artistId,
    data,
  }: {
    accountId?: string;
    artistId?: string;
    data: MarketingAssistantUserInfoModel;
  }) => await axios.put(`/account/${accountId}/artist-info/${artistId}`, data);
}

export default MarketingAssistantAPI;

import axios from 'axios';

import {
  CreateReleaseCycleModel,
  ReleaseCycleModel,
  ReleaseCycleScheduleAPIModel,
  ReleaseCycleTaskCompletionStatusAPIModel,
} from '@/models/ReleaseCycles';

class ReleaseCyclesAPI {
  static createReleaseCycle = async ({ data }: { data: CreateReleaseCycleModel }) =>
    await axios.post<ReleaseCycleModel>(`/release-cycle`, data);

  static getReleaseCycles = async ({ accountId, isCompleted }: { accountId: string; isCompleted?: boolean }) =>
    await axios.get<ReleaseCycleModel[]>(`/release-cycle/account/${accountId}${isCompleted ? '/completed' : ''}`);

  static getAccountReleaseCycleSchedules = async ({ accountId }: { accountId: string }) =>
    await axios.get<ReleaseCycleScheduleAPIModel>(`/release-cycle/schedule/account/${accountId}`);

  static getReleaseCycleSchedule = async ({
    scheduleId,
    accountId,
    startDate,
    endDate,
  }: {
    scheduleId: string;
    accountId: string;
    startDate?: string;
    endDate?: string;
  }) =>
    await axios.get<ReleaseCycleScheduleAPIModel>(
      `/release-cycle/schedule/${accountId}/${scheduleId}${startDate ? `?startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`
    );

  static getReleaseCycleTaskCompletionStatuses = async ({
    scheduleId,
    accountId,
  }: {
    scheduleId?: string;
    accountId: string;
  }) =>
    await axios.get<ReleaseCycleTaskCompletionStatusAPIModel>(
      `/release-cycle/schedule${!scheduleId ? `/account` : ''}/${accountId}${scheduleId ? `/${scheduleId}` : ''}/completion`
    );

  static markAdviceAsRead = async ({
    scheduleId,
    adviceId,
    date,
    data,
  }: {
    scheduleId: string;
    adviceId: string;
    date: string;
    data: { accountId: string; isRead: boolean };
  }) => await axios.put(`/release-cycle/${scheduleId}/advice/${date}/${adviceId}`, data);

  static markTaskAsComplete = async ({
    scheduleId,
    taskId,
    date,
    data,
  }: {
    scheduleId: string;
    taskId: string;
    date: string;
    data: { accountId: string; isCompleted: boolean };
  }) => await axios.put(`/release-cycle/${scheduleId}/task/${date}/${taskId}`, data);

  static completeReleaseCycle = async ({ scheduleId, accountId }: { scheduleId: string; accountId: string }) =>
    await axios.put(`/release-cycle/${scheduleId}/complete`, { accountId });

  static updateReleaseCycle = async ({
    scheduleId,
    details,
  }: {
    scheduleId: string;
    details: CreateReleaseCycleModel;
  }) => await axios.put(`/release-cycle/${scheduleId}`, details);

  static deleteReleaseCycle = async ({ scheduleId, accountId }: { scheduleId: string; accountId: string }) =>
    await axios.delete<ReleaseCycleModel[]>(`/release-cycle/${scheduleId}`, { data: { accountId } });
}

export default ReleaseCyclesAPI;
